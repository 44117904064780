import * as React from "react";
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import about_us from '../assets/about_main_img.webp';
import imglinebox from '../assets/imglinebox.svg';
import '../styles/screens/About.scss';

const About: React.FC = () => {
  // const [from, setFrom] = React.useState<string>('City');

  return (
    <Layout>
      <Helmet>
        <title>Создание сервисов в Тирасполе</title>
      </Helmet>
      <section className="about_us container d-flex justify-content-center py-5">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="about_us_box">
              <img src={imglinebox} className="animated fadeInDown slow" alt="about_us before"/>
              <img src={about_us} className="animated zoomIn slow" alt="about_us"/>
              <img src={imglinebox} className="animated fadeInUp slow" alt="about_us after"/>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <h6 className="about_us_title mb-4 mt-5">О нас</h6>
            <p className="content animated zoomIn slow">
              Веб-студия «ArtCode» работает с интернет-магазинами, b2b решениями,
               туристическими компаниями, финансовыми учреждениями и другими бизнес-структурами.
               Помогает оптимизировать, масштабировать и улучшать свои бизнес-процессы за счет эффективных
               внедрений множества интеллектуальных технологий. Благодаря инновационному
               и автоматизированному подходу, мы сглаживаем рабочий процесс, чтобы ускорить
               рост организации за счет использования минимальных ресурсов.
               Мы идем в ногу со временем, поэтому используем самые свежие и проверенные разработки
               инструменты для создания и раскрутке сайтов, мобильных приложений, сервисов, рекламы.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default About;